html, body {
  margin: 0 !important;
  overflow: hidden;
  height: 100% !important;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.125 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

#viewport {
  height: 100% !important;
}

.silent-iframe {
  border-width: 0;
  position: absolute;
  top: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}